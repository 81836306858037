<template>
    <validation-observer ref="observer" v-slot="{ invalid }">
        <validation-provider
            v-slot="{ errors }"
            name="select"
            rules="required"
            v-if="
                currentUserType.key === 'master_admin' ||
                currentUserType.key === 'tenant_admin'
            "
        >
            <v-select
                v-model="selectedTenantId"
                :items="
                    currentUserType.key === 'master_admin'
                        ? tenants
                        : tenantItems
                "
                item-text="tenant_identification"
                item-value="id"
                :error-messages="errors"
                label="Select Tenancy"
                required
            ></v-select>
        </validation-provider>
        <validation-provider
            v-slot="{ errors }"
            name="select-module"
            rules="required"
            v-if="selectedTenant"
        >
            <v-select
                v-model="selectedModuleId"
                :items="selectedTenant.modules"
                item-text="name"
                item-value="id"
                :error-messages="errors"
                label="Select Module"
                required
            ></v-select>
        </validation-provider>
        <v-row>
            <v-col
                v-bind:key="'moduleFile-' + moduleFile.id"
                v-for="(moduleFile, index) in currentModuleFiles"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="mt-3"
            >
                <v-card>
                    <v-card-title>
                        {{ moduleFile.name }}
                    </v-card-title>
                    <v-card-text>
                        <form @submit.prevent="() => submit(moduleFile.id)">
                            <v-file-input
                                required
                                :label="moduleFile.name + ' File'"
                                truncate-length="15"
                                v-model="currentModuleFiles[index].file"
                                accept=".xlsx,xls,.csv"
                            ></v-file-input>
                            <v-btn
                                class="mr-4"
                                @click="() => submit(moduleFile.id)"
                                :disabled="invalid"
                            >
                                Upload
                                <v-icon right color="green">
                                    mdi-cloud-upload
                                </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        Download Template
                                        <v-icon right color="blue">
                                            mdi-cloud-download
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        :href="moduleFile.file_path_xlsx"
                                        target="_blank"
                                    >
                                        <v-list-item-title>
                                            <v-icon color="blue">
                                                mdi-microsoft-excel
                                            </v-icon>
                                            Excel Format
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        :href="moduleFile.file_path_csv"
                                        target="_blank"
                                    >
                                        <v-list-item-title>
                                            <v-icon color="blue">
                                                mdi-file-delimited-outline
                                            </v-icon>
                                            Csv Format
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <!--              <v-btn :href="moduleFile.file_path" target="_blank">-->
                            <!--                Download Template-->
                            <!--                <v-icon right color="blue">-->
                            <!--                  mdi-cloud-download-->
                            <!--                </v-icon>-->
                            <!--              </v-btn>-->
                        </form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </validation-observer>
</template>
<script>
import { required, regex } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";
import { mapActions, mapGetters } from "vuex";

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("regex", {
    ...regex,
    message: "{_field_} {_value_} does not match {regex}",
});

export default {
    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {
        ValidationProvider,
        ValidationObserver,
    },

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        name: "",
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        moduleOptions: [],
        selectedTenantId: "",
        selectedTenant: {},
        selectedModuleId: "",
        currentModuleFiles: [],
        tenantItems: [],
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {
        ...mapGetters([
            "tenants",
            "modules",
            "currentUserType",
            "currentTenant",
        ]),
    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {},

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {
        ...mapActions(["fetchModules", "getAllTenants", "uploadFile"]),

        submit(fileId) {
            // let fileName = "";
            const moduleFile = this.currentModuleFiles.find(
                (cmf) => cmf.id == fileId,
            );

            if (moduleFile == null) {
                this.$swal.fire({
                    timer: 4000,
                    icon: "error",
                    title: "There was a problem",
                    text: "Module File not found",
                });
            }

            // fileName = this.getUploadFileName(moduleFile.slug);

            const form = {
                module_id: this.selectedModuleId,
                document_type: moduleFile.slug,
                data_file: moduleFile.file,
                tenantId: this.selectedTenantId,
            };

            if (form.data_file == null) {
                this.$swal.fire({
                    timer: 4000,
                    icon: "error",
                    title: "File not selected",
                    text: "Please select a file before continuing",
                });

                return;
            }

            this.uploadFile(form)
                .then((message) => {
                    moduleFile.file = null; //clears the input field

                    if (message.status == "success") {
                        this.$swal.fire({
                            timer: 6000,
                            icon: "success",
                            title: "Success",
                            text:
                                message.data ||
                                "The file is being processed, an email will arrive shortly.",
                        });
                    } else {
                        this.$swal.fire({
                            timer: 4000,
                            icon: "error",
                            title: "Sorry!",
                            text: "There was an error uploading the file",
                        });
                    }
                })
                .catch(() => {
                    this.$swal.fire({
                        timer: 4000,
                        icon: "error",
                        text: "There was a problem uploading the file",
                        title: "Something Bad Happened",
                    });

                    moduleFile.file = null; //clears the input field
                });
        },

        clear() {
            this.name = "";
            this.select = null;
            this.$refs.observer.reset();
        },
    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {
        selectedTenantId(value) {
            let tenantList =
                this.currentUserType.key === "master_admin"
                    ? this.tenants
                    : this.tenantItems;

            this.selectedTenant = tenantList.find(
                (tenant) => tenant.id == value,
            );
        },

        selectedModuleId(value) {
            const module = this.modules.find((mod) => mod.id == value);

            if (module != null) {
                this.currentModuleFiles = module.file_type;
            }
        },
    },

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {},

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {
        this.fetchModules();

        if (this.currentUserType.key != "master_admin") {
            this.selectedTenantId = this.currentTenant.id;
            this.selectedTenant = this.currentTenant;
            this.tenantItems = [this.currentTenant];
        }

        if (this.currentUserType.key == "master_admin") {
            this.getAllTenants();
        }
    },
};
</script>
