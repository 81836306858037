<template>
  <div>
    <!--  <v-card>-->
    <!--    <v-card-title>-->
    <h1>{{ currentRouteName }}</h1>
    <!--    </v-card-title>-->
    <!--    <v-card-text>-->
    <master-data-form></master-data-form>
    <!--    </v-card-text>-->

    <!--  </v-card>-->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MasterDataForm from "../../components/tenant/MasterDataForm.vue";

export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: {
    MasterDataForm
  },

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data: () => ({
    
  }),

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions([
      'getAllTenants'
    ]),
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {
    this.getAllTenants();
  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {

  },
};
</script>

<style>
</style>